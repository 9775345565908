import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function ContactPage() {
  return (
    <Layout>
      <SEO title="Contact" />
      <h2>Contact</h2>
      <form
        name="contact"
        method="post"
        netlify-honeypot="bot-field"
        data-netlify-honeypot="bot-field"
        data-netlify="true"
        style={styles.form}
      >
        <input type="hidden" name="bot-field" />
        <label style={styles.row}>
          Name
          <input type="text" name="name" id="name" />
        </label>
        <label style={styles.row}>
          Email
          <input type="email" name="email" id="email" />
        </label>
        <label style={styles.row}>
          Subject
          <input type="text" name="subject" id="subject" />
        </label>
        <label style={styles.row}>
          Message
          <textarea name="message" id="message" rows="5" />
        </label>
        <button type="submit" style={styles.submit}>
          Send
        </button>
      </form>
    </Layout>
  )
}

const styles = {
  form: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  submit: {
    borderRadius: "50px",
    padding: "0.2rem 1rem",
    alignSelf: "flex-end",
    fontSize: "14px",
    fontWeight: "bold",
    fontFamily: `"SFMono-Regular", Consolas, "Roboto Mono", "Droid Sans Mono",
    "Liberation Mono", Menlo, Courier, monospace`,
  },
}
